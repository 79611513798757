<template>
  <app-navigation
    class="public-access-header"
    data-test="header-app-navigation"
  >
    <app-navigation-menu-item>
      <a
        :href="forCandidatesUrl"
        class="public-access-header__link"
        data-test="for-candidates"
      >
        {{ $t(`${langPath}.linkForCandidates`) }}
      </a>
    </app-navigation-menu-item>

    <app-navigation-menu-item>
      <a
        :href="forCompaniesUrl"
        class="public-access-header__link"
        data-test="for-companies"
      >
        {{ $t(`${langPath}.linkForCompanies`) }}
      </a>
    </app-navigation-menu-item>

    <app-navigation-menu-item>
      <a
        :href="signinUrl"
        class="public-access-header__link"
        data-test="sign-in"
      >
        {{ $t(`${langPath}.linkSignin`) }}
      </a>
    </app-navigation-menu-item>
  </app-navigation>
</template>

<script>
import AppNavigation from '@/components/App/AppNavigation'
import AppNavigationMenuItem from '@/components/App/AppNavigationMenuItem'

import { forCandidatesUrl, forCompaniesUrl, signinUrl } from '@/helpers/constants/external-urls'

export default {
  name: 'PublicHeader',

  components: {
    AppNavigation,
    AppNavigationMenuItem
  },

  data () {
    return {
      langPath: __langpath,
      forCandidatesUrl,
      forCompaniesUrl,
      signinUrl
    }
  }
}
</script>

<style lang="scss" scoped>
.public-access-header {
  box-shadow: $shadow-16;
  display: flex;
  padding: 0 $base*3;
  position: fixed;
  z-index: 20;

  &__link {
    color: var(--tx-neutral-base);
    font: var(--tx-button-small);
  }
}

</style>
