<template>
  <main class="public-access">
    <public-access-header />

    <router-view />
  </main>
</template>

<script>
import PublicAccessHeader from './PublicAccess/components/PublicAccessHeader'

export default {
  name: 'PublicAccess',

  components: { PublicAccessHeader }
}
</script>
